import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut, signInWithEmailAndPassword, getIdTokenResult } from 'firebase/auth';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { app } from '../firebase';

// Initialize Firebase

const firestore = getFirestore(app);
const auth = getAuth(app);

const baseConfig = {
  userProfilePath: '/users/',
  userAdminProp: 'isAdmin',
  localStorageTokenName: 'RAFirebaseClientToken',
  handleAuthStateChange: async (authUser, config) => {
    if (authUser) {
      const userDoc = doc(firestore, config.userProfilePath + authUser.user.uid);
      const snapshot = await getDoc(userDoc);
      const profile = snapshot.data();

      if (profile && profile[config.userAdminProp]) {
        const firebaseToken = await authUser.user.getIdToken();
        let userData = { authUser, profile, firebaseToken };
        localStorage.setItem(config.localStorageTokenName, firebaseToken);
        return userData;
      } else {
        signOut(auth);
        localStorage.removeItem(config.localStorageTokenName);
        throw new Error('sign_in_error');
      }
    } else {
      localStorage.removeItem(config.localStorageTokenName);
      throw new Error('sign_in_error');
    }
  }
};

export default (config = {}) => {
  config = { ...baseConfig, ...config };

  const firebaseLoaded = () =>
    new Promise(resolve => {
      onAuthStateChanged(auth, resolve);
    });

  return async (type, params) => {
    if (type === AUTH_LOGOUT) {
      config.handleAuthStateChange(null, config).catch(() => { });
      return signOut(auth);
    }

    const currentUser = auth.currentUser;

    if (currentUser) {
      await currentUser.reload();
    }

    if (type === AUTH_CHECK) {
      await firebaseLoaded();

      if (!auth.currentUser) {
        throw new Error('sign_in_error');
      }

      return true;
    }

    if (type === AUTH_GET_PERMISSIONS) {
      await firebaseLoaded();

      if (!auth.currentUser) {
        throw new Error('sign_in_error');
      }

      const token = await getIdTokenResult(auth.currentUser);
      return token.claims;
    }

    if (type === AUTH_LOGIN) {
      const { username, password, alreadySignedIn } = params;
      let user = auth.currentUser;

      if (!user || !alreadySignedIn) {
        user = await signInWithEmailAndPassword(auth, username, password);
      }

      return config.handleAuthStateChange(user, config);
    }

    return false;
  };
};