import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBxoOY_pYzthXdaqplPkswJUKXA82ux4VU",
  authDomain: "lanote-7d97a.firebaseapp.com",
  databaseURL: "https://lanote-7d97a.firebaseio.com",
  projectId: "lanote-7d97a",
  storageBucket: "lanote-7d97a.appspot.com",
  messagingSenderId: "631334301221",
  appId: "1:631334301221:web:2f38f888aee82b289e99cc",
  measurementId: "G-SKR4Q9LWD5"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

export { app, functions, httpsCallable, firebaseConfig };
