import React from 'react';
import { Admin, Resource } from 'react-admin';

import { UserList, UserEdit, UserCreate } from './users';
import UserIcon from '@material-ui/icons/Group';
import { AuthProvider } from './lib';

import {
  FirebaseDataProvider
} from 'react-admin-firebase'

import { app, firebaseConfig } from './firebase'

const options = {
  logging: true,
  app: app,
  persistence: 'session',
  disableMeta: true,
  transformToDb: (resourceName, documentData) => {
    // remove undefined fields from the document, since firestore does not accept undefined as a field value
    console.log(resourceName)
    return Object.fromEntries(
      Object.entries(documentData).filter(([, val]) => val !== undefined)
    );
  }
}

const dataProvider = FirebaseDataProvider(firebaseConfig, options)

const App = () => (
  <Admin
    disableTelemetry
    dataProvider={dataProvider}
    authProvider={AuthProvider()}
  >
    <Resource name="user" list={UserList} icon={UserIcon} edit={UserEdit} create={UserCreate} />
  </Admin>
)

export default App;